import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol
        lg={this.props.collg}
        md={this.props.colmd}
        className="pb-card d-flex"
      >
        <MDBCard className="flex-fill">
          {this.props.image && (
            <GatsbyImage
              top
              image={this.props.image}
              overlay="white-slight"
              hover
              waves
              alt={this.props.alttext}
              className="rounded-top drop-shadow"
            />
          )}
          <MDBCardBody>
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className={`font-alt font-w-700 mt-2 letter-spacing-1 ` + (this.props.titleclass ? this.props.titleclass : `title-small title-xs-medium`)}>
                {this.props.title}
              </h3>
              {this.props.subtitle != '' && this.props.subtitle != null && (
                <>
                <div className={this.props.subclass ? "font-w-400 text-xs-medium text-medium-small text-lg-large mt-2" : "font-w-400 text-xs-medium text-medium text-lg-large mt-2"} dangerouslySetInnerHTML={{__html: this.props.subtitle}} />
                </>
              )}
              {this.props.subtitlenote != '' && this.props.subtitlenote != null && (
                <>
                <p className="text-muted text-extra-small mt-1">{this.props.subtitlenote}</p>
                </>
              )}              
            </div>
            <hr />
            {this.props.link ? (
              <MDBCardText className={`font-w-400 mt-2 mb-2 ` + this.props.descriptionClass ? this.props.descriptionClass : 'text-medium'}>
                {(this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/') ? (
                  <Link to={(this.props.link.indexOf('?') > -1 || this.props.link.indexOf('#') > -1 || this.props.link.slice(-1) == "/") ? this.props.link : '/' + this.props.link + '/'} className="effect">
                    {this.props.description}
                  </Link>
                ) : (
                  <a href={this.props.link} className="effect" target="_blank" rel="noopener" > {this.props.description} </a>
                )}
              </MDBCardText>
            ) : (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2"></MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card